import { createClient, OperationResult } from 'urql';
import { User } from '@/configs/nft';
import { prod } from '@/common/project-environment';

const API_URL_DEV = 'https://api.thegraph.com/subgraphs/name/neco-fun/neco-fun-nft';
const API_URL = 'https://api.thegraph.com/subgraphs/name/neco-fun/neco-fun-nft-prod';

const client = createClient({
  url: prod() ? API_URL : API_URL_DEV,
});

export function getCurrentUser(address: string): Promise<Array<User>> {
  const query = `
  query {
    users(where: { address: "${address}" }) {
      id
      address
      nfts {
        id
        nft {
          id
          nftId
          nftType1
          nftType2
          metadataUrl
          createTime
        }
        quantity
      }
    }
  }
`;
  return client.query(query).toPromise()
    .then((
      res: OperationResult<{users: Array<User>}, Record<string, any>>,
    ) => res?.data?.users || []);
}

export function test(): Promise<OperationResult<User, Record<string, any>>> {
  const tokensQuery = `
  query {
    marketplaces(first: 5) {
    id
    publishedItems {
      id
      itemId
      nftId
      nftUri
      amount
      price
    }
    soldItems {
      id
      itemId
      nftId
      nftUri
      amount
      price
    }
    totalPublishedItemAmount
  }
  }
`;
  return client.query(tokensQuery).toPromise();
}
