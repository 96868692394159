export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
        "mySell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Sell"])},
        "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "fishingGear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fishing Gear"])},
        "fish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fish"])},
        "treasureBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treasure Box"])},
        "vegetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegetable"])},
        "bambooRod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bamboo rod"])}
      },
      "zh": {
        "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场"])},
        "mySell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的出售"])},
        "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已出售"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史记录"])},
        "fishingGear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渔具"])},
        "fish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鱼"])},
        "treasureBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宝箱"])},
        "vegetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蔬菜"])},
        "bambooRod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹子鱼竿"])}
      }
    }
  })
}
