
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['modelValue'],
  emits: ['update:modelValue'],

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value: Array<string | number>) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});
