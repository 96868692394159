export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My NFTs"])},
        "bambooRod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bamboo rod"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have NFT"])}
      },
      "zh": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的NFTs"])},
        "bambooRod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹子鱼竿"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有NFT"])}
      }
    }
  })
}
